var exports = {};
exports = {
  A: {
    D: {
      "1": "T h H kB vB wB",
      "33": "0 1 2 3 4 5 6 7 8 9 I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R S V W X Y Z a b c d e f g"
    },
    L: {
      "1": "H"
    },
    B: {
      "1": "T h H",
      "2": "C K L G M N O",
      "33": "P Q R S V W X Y Z a b c d e f g"
    },
    C: {
      "1": "X Y Z a b c d e f g T h H kB",
      "2": "0 1 2 3 4 5 6 7 8 9 sB gB I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W tB uB"
    },
    M: {
      "1": "T"
    },
    A: {
      "2": "J D E F A B rB"
    },
    F: {
      "1": "jB S",
      "2": "F B C 6B 7B 8B 9B eB pB AC fB",
      "33": "0 1 2 3 4 5 6 7 8 9 G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R"
    },
    K: {
      "2": "A B C eB pB fB",
      "33": "U"
    },
    E: {
      "1": "G 4B nB oB",
      "2": "5B",
      "33": "I i J D E F A B C K L xB lB yB zB 0B 1B mB eB fB 2B 3B"
    },
    G: {
      "1": "UC nB oB",
      "33": "E lB BC qB CC DC EC FC GC HC IC JC KC LC MC NC OC PC QC RC SC TC"
    },
    P: {
      "33": "I dC eC fC gC hC mB iC jC kC lC mC nC"
    },
    I: {
      "1": "H",
      "33": "gB I WC XC YC ZC qB aC bC"
    }
  },
  B: 6,
  C: ":autofill CSS pseudo-class"
};
export default exports;